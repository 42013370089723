import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  createContext,
} from "react";
import "./App.css";
import Footer from "../components/nav/footer/_Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RoutingPath from "../routes/RoutingPath";
import { AuthProvider } from "../context/AuthProvider";
import axios from "axios";
import Lang from "../utility/language/Lang";

export const ThemeContext = createContext(null);

const API_ENDPOINT = window.location.href.includes("3000")
  ? process.env.REACT_APP_API_ENDPOINT
  : window.location.href.substr(0, window.location.href.lastIndexOf("\\")) +
    "api";

const Portfolio_Landing = lazy(() =>
  import("../view/portfolio/Portfolio_Landing")
);
const Reports_Landing = lazy(() => import("../view/reports/Reports_Landing"));
const Fund_Landing = lazy(() => import("../view/fund/Fund_Landing"));
const BuySellView = lazy(() => import("../view/buySell/BuySell_V.js"));
const FaqView = lazy(() => import("../view/faq/Faq_V.js"));
const DocumentsView = lazy(() => import("../view/documents/Documents_V.js"));
const UserView = lazy(() => import("../view/user/User_V.js"));
const SavingsView = lazy(() => import("../view/savings/Savings_V.js"));
const PrivacyPolicyView = lazy(() =>
  import("../view/privacyPolicy/PrivacyPolicy_V.js")
);
const LoginView = lazy(() => import("../view/login/Login_V.js"));
const WithNav = lazy(() => import("./WithNav"));
const WithoutNav = lazy(() => import("./WithoutNav"));
let licenses = null;
let user = false;

axios.defaults.withCredentials = true;
function App() {
  const [fundCustomerList, setFundCustomerList] = useState([]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    // Define a function to handle changes to sessionStorage
    const handleStorageChange = () => {
      // Check if user data is not available in sessionStorage and the last part of the URL is not just "/" or "/login"
      const lastPartOfUrl = window.location.pathname.split("/").pop();
      if (
        !sessionStorage.getItem("User") &&
        lastPartOfUrl !== "" &&
        lastPartOfUrl !== "login"
      ) {
        logoutUser();
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Call the function immediately to check the initial state of sessionStorage
    handleStorageChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const GetFundCustomerList = async () => {
    try {
      axios
        .get(API_ENDPOINT + "/fund/fundcustomer", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 400 || response.status === 401) {
            //
            logoutUser();
          }

          const data = response?.data;

          const filteredFundCustomerList = data?.filter(
            (customer) => customer?.customerType !== "Juridisk person"
          );

          setFundCustomerList(filteredFundCustomerList);
        });
    } catch (err) {
      console.error("Error getting customer list");
    }
  };

  useEffect(() => {
    licenses = JSON.parse(sessionStorage.getItem("User"));
    user = true;

    if (
      licenses?.isFundUser &&
      licenses?.WEBFundOrder &&
      window.location.pathname !== "/login"
    ) {
      GetFundCustomerList();
    }
  }, []);

  if (sessionStorage.getItem("User") && licenses === null) {
    licenses = JSON.parse(sessionStorage.getItem("User"));
    user = true;
  }

  const logoutUser = () => {
    window.location.href =
      window.location.href.replace(window.location.pathname, "") + "/login";
  };

  return (
    <React.StrictMode>
      <AuthProvider>
        <div className="App font-link">
          <div className="wrap">
            <Suspense fallback={<p>{Lang("txtLoading")}</p>}>
              <Router>
                <Routes>
                  <Route element={<WithoutNav />}>
                    <Route
                      exact
                      path={RoutingPath.login}
                      element={
                        <Suspense fallback={<LoginView />}>
                          <LoginView />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route element={<WithoutNav />}>
                    <Route
                      index
                      element={
                        <Suspense fallback={<LoginView />}>
                          <LoginView />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route element={<WithNav />}>
                    <Route
                      exact
                      path={RoutingPath.documents}
                      element={
                        <Suspense>
                          <DocumentsView logout={logoutUser} />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route element={<WithNav />}>
                    {licenses !== null && licenses?.isPortfolioUser ? (
                      <Route
                        exact
                        path={RoutingPath.reports}
                        element={
                          <Suspense>
                            <Reports_Landing logout={logoutUser} />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>
                  <Route element={<WithNav />}>
                    <Route
                      exact
                      path={RoutingPath.faq}
                      element={
                        <Suspense>
                          <FaqView logout={logoutUser} />
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route element={<WithNav />}>
                    {fundCustomerList.length > 0 ? (
                      <Route
                        exact
                        path={RoutingPath.buySell}
                        element={
                          <Suspense>
                            <BuySellView logout={logoutUser} />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>
                  <Route element={<WithNav />}>
                    {licenses?.WEBFund ? (
                      <Route
                        exact
                        path={RoutingPath.user}
                        element={
                          <Suspense>
                            <UserView logout={logoutUser} session={licenses} />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>
                  <Route element={<WithNav />}>
                    {licenses?.WEBFundMonthlySavings ? (
                      <Route
                        exact
                        path={RoutingPath.savings}
                        element={
                          <Suspense>
                            <SavingsView
                              logout={logoutUser}
                              session={licenses}
                            />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>
                  <Route element={<WithoutNav />}>
                    <Route
                      exact
                      path={RoutingPath.privacyPolicy}
                      element={
                        <Suspense>
                          <PrivacyPolicyView session={licenses} />
                        </Suspense>
                      }
                    />
                  </Route>

                  <Route element={<WithNav />}>
                    {licenses?.isPortfolioUser && licenses?.WEBPortfolio ? (
                      <Route
                        exact
                        path={RoutingPath.portfolio}
                        element={
                          <Suspense>
                            <Portfolio_Landing logout={logoutUser} />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>
                  <Route element={<WithNav />}>
                    {licenses?.isFundUser && licenses?.WEBFund ? (
                      <Route
                        exact
                        path={RoutingPath.fund}
                        element={
                          <Suspense fallback={<p>Loading</p>}>
                            <Fund_Landing logout={logoutUser} />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>

                  <Route element={<WithNav />}>
                    {licenses?.isPortfolioUser && licenses?.WEBPortfolio ? (
                      <Route
                        path="*"
                        element={
                          <Suspense>
                            <Portfolio_Landing logout={logoutUser} />
                          </Suspense>
                        }
                      />
                    ) : licenses?.isFundUser && licenses?.WEBFund ? (
                      <Route
                        path="*"
                        element={
                          <Suspense>
                            <Fund_Landing logout={logoutUser} />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </Route>
                </Routes>
                {/* <Footer /> */}
              </Router>
            </Suspense>
          </div>
        </div>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
