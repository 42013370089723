function sanitizeInput(input) {
    if (typeof input !== 'string') return ''; // Ensure input is a string

    // Remove dangerous characters and HTML tags
    const sanitizedInput = input
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');

    return sanitizedInput;
}

module.exports = {
    sanitizeInput,
};

{/*  This is our own inut-sanitizer. This screens the input and converts dangerous html tags into its html entity equivalents. */ }