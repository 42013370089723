const home = "/";
const portfolio = "/portfolio";
const fund = "/fund";
const login = "/login";
const reports = "/reports";
const documents = "/documents";
const contact = "/contact";
const faq = "/faq";
const dependencies = "/dependencies";
const user = "/user";
const buySell = "/buysell";
const monthlySaving = "/monthly-saving";
const privacyPolicy = "/privacy-policy";
const savings = "/savings";

// eslint-disable-next-line
export default {
  login,
  home,
  portfolio,
  fund,
  documents,
  contact,
  faq,
  dependencies,
  user,
  reports,
  buySell,
  monthlySaving,
  privacyPolicy,
  savings,
};
