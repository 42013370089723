import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

const API_ENDPOINT = window.location.href.includes("3000")
  ? process.env.REACT_APP_API_ENDPOINT
  : window.location.href.substr(0, window.location.href.lastIndexOf("\\")) +
    "api";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to the console
    console.error("ErrorBoundary caught an error", error, errorInfo);

    // Simplify the error object to send
    const errorData = {
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
    };

    // Log the error to an external service using axios
    axios
      .post(API_ENDPOINT + "/error/log", { error: errorData })
      .then((response) => {})
      .catch((err) => {
        console.error("Error logging failed", err);
      });
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
