import * as React from "react";
import Tooltip from "@mui/material/Tooltip";

export default function BasicTooltip(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className="PopoverParent"
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Tooltip
        followCursor
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        transformorigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        placement="top"
        title={props.txt}
      >
        {props.content}
      </Tooltip>
    </div>
  );
}
