import { createTheme } from "@mui/material/styles";
const customer = sessionStorage.getItem("css");

const theme = createTheme({
  typography: {
    fontFamily:
      customer === "lannebo"
        ? "Lusang, sans-serif"
        : customer === "norse"
        ? "Open Sans, sans-serif"
        : customer === "isec"
        ? "Inter, sans-serif"
        : customer === "demo_green"
        ? "Inter, sans-serif"
        : customer === "demo_blue"
        ? "Inter, sans-serif"
        : customer === "demo_purple"
        ? "Inter, sans-serif"
        : "Inter, sans-serif",
  },
});

export default theme;
