function Lang(input) {
  const defaultLng = "se";
  var lng = !!localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : defaultLng;

  const en = {
    translation: {
      txtSavingsHyperlink: "Edit monthly saving",

      txtFond: "Fund",
      txtToAccount: "To account",
      txtFromAccount1: "From account",
      txtMission: "Mission",
      txtStartDate: "Start date",
      txtPayDay: "Pay day",

      txtSavings: "Monthly savings",

      txtCurrency: "Currency",
      txtOther: "Other",
      //Table Portfolio
      designation: "Securities",
      quantity: "Quantity",
      localPurchasePrice: "Purchase price",
      localMarketValue: "Market value",
      localUnrealizedProfitLoss: "Unrealized p/l",
      change: "Change",
      localAveragePurchasePrice: "Average purchase price",
      localPrice: "Price",
      priceDate: "Price date",
      ///////////////////////

      txtAcceptCookies: "Accept Cookies",
      txtDeclineCookies: "Accept Only Essential Cookies",
      txtCookiePopupText: "We use cookies for monitoring purposes",
      txtLearnMore: "Learn more",
      txtBackToLogin: "Back to login",

      txtLocalMarketValue: "Market value",
      txtLocalActualPurchaseValue: "Purchase value",

      txtUnitsAvailable: "Units available",
      txtAmountAvailable: "Amount available",

      //Lannebo
      txtLanneboFund: "Fund development",
      txtLanneboPortfolioTopNav: "Overview",
      txtLanneboPortfolioDropdown: "Account",
      txtLanneboGroupTypeDropdown: "Grouping",

      txtBuyISK: "Buy ISK",
      txtBuyISKSubscription: "Subscription ISK",
      txtWithdrawFromISKCash: "Withdraw from ISK to bank account",
      txtWithdraw: "Withdraw from ISK",
      txtWithdrawOrderType: "Withdraw from ISK account",
      txtWithdrawBtn: "Withdraw",

      txtAccounts: "Accounts",
      //login
      txtRememberMe: "Remember me",
      txtWelcome: "Welcome",
      txtLoginWith: "Login using: ",
      txtUsername: "Username",
      txtPassword: "Password",
      txtLogin: "Login",
      txtLoginInfo:
        "In our customer portal, you can follow and manage your savings with us",

      txtForgottenPassword: "Forgotten your password?",
      txtBack: "Back",
      txtAssently: "BankID",
      txtUsernameAndPassword: "Username and password",
      txtDarkMode: "Dark mode",
      txtLightMode: "Light mode",
      txtLoginError: "Login error",
      txtLoginInfoNoBankID:
        "Don't have BankID? Make a one-time payment via bankgiro",

      //NAV
      txtPortfolio: "Portfolio",
      txtFund: "Fund account",
      txtBuySell: "Buy/Sell/Switch",
      txtReports: "Reports",
      txtLogout: "Logout",
      txtSelected: "Accounts",
      txtUser: "User Info",

      //Portfolio_V
      txtPortfolioDropdown: "Portfolio/ISK",
      txtGroupTypeDropdown: "Group type",
      txtReturnOnInvestmentDropdown: "Return on investment",
      txtReportType: "Report type",
      txtListType: "List type",
      txtCashType: "Cash type",

      //Graf
      txtWeek: "Week",
      txtMonth: "Month",
      txtYear: "1 Year",
      txt3Years: "3 Years",
      txtFromStart: "From start",
      txtThisYear: "This year",
      txtBarChartHeader: "Overview",
      txtPieChartHeader: "Fund distribution",

      //Allocation
      txtAllocation: "Allocation",
      txtInvestments: "Investments",

      //Datepickers
      txtFrom: "From",
      txtTo: "To",

      //TABLES
      txtRowsPerPage: "Rows per page",
      txtOf: "of",

      //Overview table
      txtOverview: "Overview",

      //Transactions table
      txtTransactions: "Transactions",
      txtDescription: "Description",
      txtFundName: "Fund name",
      txtUnits: "Units",
      txtBusinessDate: "Business date",
      txtSettlementDate: "Settlement date",
      txtTransactionDate: "Transaction date",
      txtPrice: "Price",
      txtType: "Type",
      txtAmount: "Amount",

      //Positions table
      txtPositions: "Holdings",
      txtCashFLows: "Cash Flows",
      txtOpeningBalance: "Opening",
      txtClosingBalance: "Closing",
      txtAcquisitionValue: "Acquisition value",
      txtFund: "Fund",
      txtSubscriptionPrice: "Subscription price",
      txtAvgPrice: "Average price",
      txtMarketValue: "Market value",
      txtUnrealizedProfitLoss: "Unrealized profit/loss",
      txtUnrealizedProfitLossPercent: "Unrealized profit/loss (%)",
      txtPriceDate: "Price date",
      txtSecurity: "Security",
      txtShareOfPortfolio: "Share of portfolio",
      txtCurrencyAccount: "Currency account",
      txtLocalAmount: "Local amount",
      txtBalance: "Balance",
      txtAccuredInterest: "Accured interest",

      //Fund Account_V
      txtFundAccount: "Fund account",
      txtFunds: "Funds",

      //DOCUMENT_V
      txtDocumentSelection: "Document Selection",
      txtDocuments: "Documents",
      txtDocument: "Document",
      txtHeader: "Header",
      txtPulicationDate: "Publication date",
      txtDocumentType: "Document type",
      txtCustomerTypeDropdown: "Customer type",
      txtFundAccountDropdown: "Fund account",
      txtDocumentTypeDropdown: "Document type",

      //REPORTS_V
      txtReports: "Reports",
      txtGenerateReport: "Generate report",
      txtReportCurrency: "Report currency",
      txtReportCashType: "Cash type",

      //BUY/SELL_V
      txtBuySellSwitch: "Buy/Sell/Switch",
      txtSubscription: "Buy",
      txtRedemption: "Sell",
      txtSwitch: "Switch",
      txtFundDropdown: "Funds",
      txtPortfoliosDropdown: "Portfolios",
      txtSellFund: " Sell fund",
      txtBuyFund: " Buy fund",
      txtSellType: "Sell type",
      txtFundISKDropdown: "Fund account/ISK",
      txtSwitchTo: "Switch to",
      txtFromAccount: "From ISK",
      txtTotal: "Total",
      txtAgree: "Agree",
      txtDisagree: "Disagree",
      txtCancel: "Cancel",
      txtBalanceISKcash: "Balance ISK cash",

      txtDeleteOrderHeader: "Are you sure you want to cancel this order?",

      //Orders Table
      txtFundNames: "Fund names",
      txtPercent: "Percent",
      txtOrders: "Orders",

      txtOrder: "Order",

      txtOrderTypeDropdown: "Order type",

      //OVERVIEW Table
      txtDetails: "Details",
      txtName: "Name:",
      txtAccountNumber: "Account number:",
      txtNationalIdNumber: "National id. Number:",
      txtCustomerName: "Customer name:",

      txtTradeDateCash: "Trade date cash:",
      txtNetAssetValue: "Net asset value:",
      txtBuyWithAutogiro: "Buy with Autogiro",
      txtAssetManager: "Asset manager",
      //popup
      txtFAQ: "FAQ",
      txtContactUs: "Contact us",

      //mock
      txtNone: "None",

      //login alerts
      txtTryAgain: "Try again",
      txtNoMatchForAccount: "No match for account",
      txtRateLimitWarning: "Too many login attempts",
      txtTryAgainIn: "Try again in",

      // other
      txtLoading: "Loading",
      txtCountryDropdown: "Country",
      txtPerformance: "Performance",
      txtBenchmark: "Benchmark",
      txtSuccess: "Success",
      txtError: "Error",
      txtDependencies: "Dependencies",
      txtClose: "Close",
      txtDescending: "Descending",
      txtAscending: "Ascending",
      txtRead: "Read",
      txtYes: "Yes",
      txtNo: "No",
      txtRowsPerPage: "Rows per page",

      //cards
      txtPerformanceCard: "Performance",
      txtNetAssetValueCard: "Net asset value",
      txtTradeDateCashCard: "Trade date cash",
      txtBestPerformingAssetCard: "Best performing asset",

      //info icon
      txtHover: "Tips: Hover over the legend!",
      txtSelectorInfoPortfolio:
        "See how your assets are performing! Try changing the group type, from- and to date and see the difference. Return on investment changes how many data points are displayed.",
      txtSelectorInfoFund:
        "See how your assets are performing! Try changing the group type, from- and to date and see the difference. Return on investment changes how many data points are displayed.",
      txtSelectorInfoDocument: "Here you can see your published documents.",
      txtSelectorInfoReports:
        "Here you can generate pdf reports. Select what type of report you want to generate and in what time span.",
      txtSelectorInfoSwitch: "Here you can buy/sell or switch your assets.",
      txtSelectorInfoUser:
        "Here you can change your email address and phone numbers connected to your account/accounts.",

      txtCardInfoPerformance:
        "How your whole portfolio has changed between the chosen from- and to date.",
      txtCardInfoNetAssetValue:
        "The value of your portfolio between the chosen from- and to date.",
      txtCardInfoTradeDateCash: "Funds available for purchases.",
      txtCardInfoDailyWinner: "Best daily performer of all your assets.",

      txtAccount: "Account",

      //User info
      txtAccountName: "Account name",
      txtEmail: "Email",
      txtAdress: "Adress",
      txtPostalCode: "Postal code",
      txtCity: "City",
      txtCountry: "Country",
      txtHomeNumber: "Home number",
      txtMobileNumber: "Mobile number",
      txtSave: "Save",
      txtSaveChanges: "Save changes",
      txtUpdateAccount: "Update account",
      txtUpdate: "Update",
      txtAll: "All",

      //FOOTER
      txtInformation: "Information",
      txtCustomerService: "Customer service",

      //Login page
      txtNoAccount: "No account yet?",
      txtWelcomeTo: "Welcome to",
      txtBankIDBtn: "Login with BankID",
      txtBecomeACustomer: "Become a customer",
      txtCredentials: "Login Details",

      txtYourInfo: "Your information",

      monthsArray: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  };

  const se = {
    translation: {
      txtSavingsHyperlink: "Hantera månadsspar",

      txtFond: "Fond",
      txtToAccount: "Till konto",
      txtFromAccount1: "Från konto",
      txtStartDate: "Startdag",
      txtPayDay: "Betaldag",

      txtMission: "Uppdrag",

      txtSavings: "Månadsspar",

      txtCurrency: "Valuta",
      txtOther: "Övrigt",

      //Table Portfolio
      designation: "Värdepapper",
      quantity: "Antal",
      localPurchasePrice: "Anskaffningsvärde",
      localMarketValue: "Marknadsvärde",
      localUnrealizedProfitLoss: "Orealiserad v/f",
      change: "Förändring %",
      localAveragePurchasePrice: "GAV",
      localPrice: "Kurs",
      priceDate: "Kursdatum",
      ///////////////////////

      txtAcceptCookies: "Acceptera kakor",
      txtDeclineCookies: "Acceptera endast nödvändiga kakor",
      txtCookiePopupText: "Vi använder kakor för övervakningsändamål",
      txtLearnMore: "Läs mer",
      txtBackToLogin: "Tillbaka till inloggning",

      txtLocalMarketValue: "Marknadsvärde",
      txtLocalActualPurchaseValue: "Anskaffningsvärde",

      txtUnitsAvailable: "Andelar tillgängligt",
      txtAmountAvailable: "Belopp tillgängligt",

      //Lannebo
      txtLanneboFund: "Fondutveckling",
      txtLanneboPortfolioTopNav: "Översikt",
      txtLanneboPortfolioDropdown: "Konto",
      txtLanneboGroupTypeDropdown: "Gruppering",

      txtBuyISK: "Köp med likvida medel",
      txtBuyISKSubscription: "Köp med likvida medel",
      txtWithdrawFromISKCash: "Uttag från ISK till eget bankkonto",
      txtWithdraw: "Uttag från ISK-kassa",
      txtWithdrawOrderType: "Uttag av likvida medel",
      txtWithdrawBtn: "Uttag",

      txtAccounts: "Konton",
      txtRememberMe: "Kom ihåg mig",
      txtWelcome: "Välkommen",
      txtLoginWith: "Logga in med: ",
      txtUsername: "Användarnamn",
      txtPassword: "Lösenord",
      txtLogin: "Logga in",
      txtLoginInfo:
        "I vår kundportal kan du följa och hantera ditt sparande hos oss",

      txtForgottenPassword: "Glömt ditt lösenord?",
      txtBack: "Tillbaka",
      txtAssently: "BankID",
      txtUsernameAndPassword: "Användarnamn och lösenord",
      txtDarkMode: "Mörkt läge",
      txtLightMode: "Ljust läge",

      //NAV
      txtPortfolio: "Portfölj/ISK",
      txtFund: "Fondöversikt",
      txtBuySell: "Köp/Byt/Sälj",
      txtReports: "Rapporter",
      txtLogout: "Logga ut",
      txtSelected: "Konton",
      txtUser: "Användarinfo",

      //Portfolio_V
      txtPortfolioDropdown: "Portfölj/ISK",
      txtGroupTypeDropdown: "Grupp",
      txtReturnOnInvestmentDropdown: "Avkastningsperiod",
      txtReportType: "Rapporttyp",
      txtListType: "Listtyp",
      txtCashType: "Valuta",
      txtDescending: "Stigande",
      txtAscending: "Fallande",

      //Graf
      txtWeek: "Vecka",
      txtMonth: "Månad",
      txtYear: "1 År",
      txt3Years: "3 År",
      txtFromStart: "Sedan start",
      txtThisYear: "I år",
      txtBarChartHeader: "Översikt",
      txtPieChartHeader: "Fondfördelning",

      //Allocation
      txtAllocation: "Allokering",
      txtInvestments: "Investeringar",

      //Datepickers
      txtFrom: "Från",
      txtTo: "Till",

      //TABLES
      txtRowsPerPage: "Rader per sida",
      txtOf: "av",

      //Overview table
      txtOverview: "Översikt",
      txtOrderTypeDropdown: "Ordertyp",

      //Transactions table
      txtTransactions: "Transaktioner",
      txtDescription: "Beskrivning",
      txtFundName: "Fondnamn",
      txtUnits: "Andelar",
      txtBusinessDate: "Affärsdatum",
      txtSettlementDate: "Likviddatum",
      txtTransactionDate: "Transaktionsdatum",
      txtPrice: "Senast kända kurs",
      txtType: "Typ",
      txtAmount: "Belopp",

      //Positions table
      txtPositions: "Innehav",
      txtCashFLows: "Kassaflöden",
      txtOpeningBalance: "Ingående kassa",
      txtClosingBalance: "Utgående kassa",

      txtFund: "Fond",
      txtSubscriptionPrice: "Anskaffningsvärde", //kanske ta bort sen
      txtAcquisitionValue: "Anskaffningsvärde",
      txtAvgPrice: "GAV",
      txtMarketValue: "Marknadsvärde",
      txtUnrealizedProfitLoss: "Orealiserad v/f",
      txtUnrealizedProfitLossPercent: "Orealiserad v/f (%)",
      txtPriceDate: "Kursdatum",
      txtSecurity: "Värdepapper",
      txtShareOfPortfolio: "Andel av portfölj",
      txtCurrencyAccount: "Valutakonto",
      txtLocalAmount: "Lokalt belopp",
      txtBalance: "Balans",
      txtAccuredInterest: "Ackumulerad ränta",

      //Fund Account_V
      txtFundAccount: "Fondkonto",
      txtFunds: "Fonder",

      //DOCUMENT_V
      txtDocumentSelection: "Välj dokument",
      txtDocuments: "Dokument",
      txtDocument: "Dokument",
      txtHeader: "Rubrik",
      txtPulicationDate: "Publiceringsdatum",
      txtDocumentType: "Dokumenttyp",
      txtCustomerTypeDropdown: "Kunddtyp",
      txtFundAccountDropdown: "Fondkonto",
      txtDocumentTypeDropdown: "Dokumenttyp",
      txtPortfoliosDropdown: "Portföljer",

      //REPORTS_V
      txtReports: "Rapporter",
      txtGenerateReport: "Generera Rapport",
      txtReportCurrency: "Rapportvaluta",
      txtReportCashType: "Kassatyp",
      //INTE FÄRDIG

      //BUY/SELL_V
      txtBuySellSwitch: "Köp/Sälj/Byt",
      txtSubscription: "Köp",
      txtRedemption: "Sälj",
      txtSwitch: "Byt",
      txtFundDropdown: "Fond",
      txtSellType: "Säljtyp",
      txtSellFund: "Sälj fond",
      txtBuyFund: " Köp fond",
      txtFundISKDropdown: "Fondkonto/ISK",
      txtSwitchTo: "Byt till",
      txtFromAccount: "Från ISK",
      txtTotal: "Totalt",
      txtAgree: "Godkänn",
      txtDisagree: "Avbryt",
      txtCancel: "Avbryt",
      txtBalanceISKcash: "Likvida medel",

      txtDeleteOrderHeader:
        "Är du säker på att du vill avbryta den här transaktionen?",

      //Orders Table
      txtFundNames: "Fondnamn",
      txtPercent: "Procent",
      txtOrders: "Pågående ordrar",

      txtOrder: "Order",

      //OVERVIEW Table
      txtDetails: "Översikt",
      txtName: "Namn:",
      txtAccountNumber: "Kontonummer:",
      txtNationalIdNumber: "Nat. id.nummer:",
      txtCustomerName: "Kundnamn:",
      txtPerformance: "Avkastning",
      txtBenchmark: "Jämförelseindex",
      txtTradeDateCash: "Affärsdagskassa:",
      txtNetAssetValue: "Innehav:",
      txtBuyWithAutogiro: "Köp med Autogiro",
      txtAssetManager: "Kapitalförvaltare:",
      //popup
      txtFAQ: "FAQ",
      txtContactUs: "Kontakta oss",

      //mock
      txtNone: "Ingen",

      //login alets
      txtTryAgain: "Försök igen",
      txtNoMatchForAccount: "Inget konto hittades",
      txtRateLimitWarning: "För många login-försök",
      txtTryAgainIn: "Försök igen om",
      txtLoginError: "Login error",
      txtLoginInfoNoBankID:
        "Har du inte BankID? Gör en engångsinsättning via bankgiro",

      // other
      txtLoading: "Laddar",
      txtCountryDropdown: "Land",
      txtSuccess: "Klart",
      txtError: "Fel har inträffat",
      txtDependencies: "Beroenden",
      txtClose: "Stäng",
      txtRead: "Läst",
      txtYes: "Ja",
      txtNo: "Nej",
      txtRowsPerPage: "Rader per sida",

      //cards
      txtPerformanceCard: "Avkastning",
      txtNetAssetValueCard: "Nettovärde",
      txtTradeDateCashCard: "Affärsdagskassa",
      txtBestPerformingAssetCard: "Bästa innehavsutveckling",

      //info icon
      txtHover: "Tips: Hovra över grafförklaringen!",
      txtSelectorInfoPortfolio:
        "Se hur dina innehav presterar! Testa ändra grupp, från- och till datum för att se skillnaden. Avkastningsperioden ändrar hur många datapunkter som visas.",
      txtSelectorInfoFund:
        "Se hur dina innehav presterar! Testa ändra grupp, från- och till datum för att se skillnaden. Avkastningsperioden ändrar hur många datapunkter som visas.",
      txtSelectorInfoDocument: "Här kan du se dina publiserade dukument.",
      txtSelectorInfoReports:
        "Här kan du generera pdf-raporter. Välj vad för typ av raport du vill generera och mellan vilket tidsspann.",
      txtSelectorInfoSwitch: "Här kan du köpa/sälja och byta dina tillgångar.",
      txtSelectorInfoUser:
        "Här kan du ändra epostadress och telefonnummer kopplad till ditt/dina konton.",

      txtCardInfoPerformance:
        "Hur hela din portfölj har ändrats mellan det valda från- och till datumet.",
      txtCardInfoNetAssetValue:
        "Värdet av din portfölj mellan det valda från- och till datumet.",
      txtCardInfoTradeDateCash: "Pengar tillgängliga för köp.",
      txtCardInfoDailyWinner: "Bäst daglig utveckling av alla dina innehav.",

      txtAccount: "Konto",

      //User info
      txtAccountName: "Kontonamn",
      txtEmail: "Epost",
      txtAdress: "Adress",
      txtPostalCode: "Postnummer",
      txtCity: "Stad",
      txtCountry: "Land",
      txtHomeNumber: "Hemnummer",
      txtMobileNumber: "Mobilnummer",
      txtSave: "Spara",
      txtSaveChanges: "Spara ändringar",
      txtUpdateAccount: "Ändra uppgifter",
      txtUpdate: "Uppdatera",
      txtAll: "Alla",

      //FOOTER
      txtInformation: "Information",
      txtCustomerService: "Kundservice",

      //Login page
      txtNoAccount: "Inget konto ännu?",
      txtWelcomeTo: "Välkommen till",
      txtBankIDBtn: "Logga in med BankID",
      txtBecomeACustomer: "Bli kund",
      txtCredentials: "Inloggningsuppgifter",

      txtYourInfo: "Dina uppgifter",

      monthsArray: [
        "januari",
        "februari",
        "mars",
        "april",
        "maj",
        "juni",
        "juli",
        "augusti",
        "september",
        "oktober",
        "november",
        "december",
      ],
    },
  };

  const no = {
    translation: {
      txtSavingsHyperlink: "Administrer månedlig sparing",

      txtFond: "Fond",
      txtToAccount: "Til konto",
      txtFromAccount1: "Fra konto",
      txtStartDate: "Start dag",
      txtPayDay: "Overføringsdato",

      txtMission: "Oppdrag",

      txtCurrency: "Valuta",
      txtSavings: "Månedlig sparing",

      txtOther: "Diverse",

      //Table Portfolio
      designation: "Verdipapir",
      quantity: "Antall",
      localPurchasePrice: "Anskaffelsesverdi",
      localMarketValue: "Markedsverdi",
      localUnrealizedProfitLoss: "Urealisert v/f",
      change: "Endring %",
      localAveragePurchasePrice: "GAV",
      localPrice: "Kurs",
      priceDate: "Kursdato",
      ///////////////////////

      txtAcceptCookies: "Godta informasjonskapsler",
      txtDeclineCookies: "Godta bare essensielle informasjonskapsler",
      txtCookiePopupText: "Vi bruker informasjonskapsler for overvåkingsformål",
      txtLearnMore: "Lær mer",
      txtBackToLogin: "Tilbake til innlogging",

      txtLocalMarketValue: "Markedsverdi",
      txtLocalActualPurchaseValue: "Anskaffelsesverdi",

      txtUnitsAvailable: "Andeler tilgjengelig",
      txtAmountAvailable: "Beløp tilgjengelig",

      //Lannebo
      txtLanneboFund: "Fondsutvikling",
      txtLanneboPortfolioTopNav: "Oversikt",
      txtLanneboPortfolioDropdown: "Konto",
      txtLanneboGroupTypeDropdown: "Gruppering",

      txtBuyISK: "Kjøp ISK",
      txtBuyISKSubscription: "Kjøp med kontanter",
      txtWithdrawFromISKCash: "Uttak fra ISK til egen bankkonto",
      txtWithdraw: "Ta ut fra ISK",
      txtWithdrawOrderType: "Uttak av likvide midler",
      txtWithdrawBtn: "Uttak",

      txtAccounts: "Kontoer",
      txtRememberMe: "Husk meg",
      txtWelcome: "Velkommen",
      txtLoginWith: "Login med",
      txtUsername: "Brukernavn",
      txtPassword: "Passord",
      txtLogin: "Logg inn",
      txtLoginInfo:
        "I vår kundeportal kan du følge med på og håndtere ditt sparing hos oss.",
      txtForgottenPassword: "Glemt ditt passord?",
      txtBack: "Tilbake",
      txtAssently: "BankID",
      txtUsernameAndPassword: "Brukernavn og Passord",
      txtDarkMode: "Mørk versjon",
      txtLightMode: "Lys versjon",
      txtLoginError: "Login feil",
      txtLoginInfoNoBankID:
        "Har du ikke BankID? Gjør en engangsinnbetaling via bankgiro",

      //NAV
      txtPortfolio: "Portefølje",
      txtFund: "Fondskonto",
      txtBuySell: "Kjøp/ Selg",
      txtReports: "Rapporter",
      txtLogout: "Logg ut",
      txtSelected: "Kontoer",
      txtUser: "Bruker Info",

      //Portfolio_V
      txtPortfolioDropdown: "Portefølje/ ISK",
      txtGroupTypeDropdown: "Gruppe type",
      txtReturnOnInvestmentDropdown: "Avkastning",
      txtReportType: "Rapport type",
      txtListType: "Liste type",
      txtCashType: "Kontant type",

      //Graf
      txtWeek: "Uke",
      txtMonth: "Måned",
      txtYear: "1 år",
      txt3Years: "3 år",
      txtFromStart: "Fra start",
      txtThisYear: "I år",
      txtBarChartHeader: "Oversikt",
      txtPieChartHeader: "Fondfordeling",

      //Allocation
      txtAllocation: "Fordeling",
      txtInvestments: "Investeringer",

      //Datepickers
      txtFrom: "Fra",
      txtTo: "Til",

      //TABLES
      txtRowsPerPage: "Rader per side",
      txtOf: "av",

      //Overview table
      txtOverview: "Oversikt",

      //Transactions table
      txtTransactions: "Transaksjoner",
      txtDescription: "Beskrivelse",
      txtFundName: "fond",
      txtUnits: "Andeler",
      txtBusinessDate: "Handelsdag",
      txtSettlementDate: "Oppgjørsdag",
      txtTransactionDate: "Transaksjonsdato",
      txtPrice: "Pris",
      txtType: "Type",
      // file deepcode ignore DuplicateObjectProperty: <please specify a reason of ignoring this>
      txtAmount: "Beløp",

      //Positions table
      txtPositions: "Holder",
      txtCashFLows: "Kontantstrøm",
      txtOpeningBalance: "Start",
      txtClosingBalance: "Slutt",
      txtAcquisitionValue: "Kjøpsverdi",
      txtFund: "Fond",
      txtSubscriptionPrice: "Tegningskurs",
      txtAvgPrice: "Gjennomsnittkurs",
      txtMarketValue: "Markedsverdi",
      txtUnrealizedProfitLoss: "Urealisert gevinst / tap",
      txtUnrealizedProfitLossPercent: "Urealisert gevinst / tap(%)",
      txtPriceDate: "Kursdato",
      txtSecurity: "Verdipapir",
      txtShareOfPortfolio: "Andel i porteføljen",
      txtCurrencyAccount: "Valutakonto",
      txtLocalAmount: "Lokal verdi",
      txtBalance: "Balanse",
      txtAccuredInterest: "Opparbeidet rente",

      //Fund Account_V
      txtFundAccount: "Fondskonto",
      txtFunds: "Fond",

      //DOCUMENT_V
      txtDocumentSelection: "Dokumentutvalg",
      txtDocuments: "Dokumenter",
      txtDocument: "Dokument",
      txtHeader: "Overskrift",
      txtPulicationDate: "Publiseringsdato",
      txtDocumentType: "Dokumenttype",
      txtCustomerTypeDropdown: "Kundetype",
      txtFundAccountDropdown: "Fondskonto",
      txtDocumentTypeDropdown: "Dokumenttype",
      //REPORTS_V
      txtReports: "Rapporter",
      txtGenerateReport: "Generere rapport",
      txtReportCurrency: "Rapport valuta",
      txtReportCashType: "Cash type",

      //BUY/SELL_V
      txtBuySellSwitch: "Kjøp / Selg / Bytte",
      txtSubscription: "Kjøp",
      txtRedemption: "Selg",
      txtSwitch: "Bytte",
      txtFundDropdown: "Fond",
      txtPortfoliosDropdown: "Porteføljer",
      txtSellFund: "Selg fond",
      txtBuyFund: "Kjøp fond",
      txtSellType: "Selg type",
      txtFundISKDropdown: "Fondskonto / ISK",
      txtSwitchTo: "Bytte til",
      txtFromAccount: "Fra ISK",
      txtTotal: "Totalt",
      txtAgree: "Samtykke",
      txtDisagree: "Uenig",
      txtCancel: "Avslutt",
      txtBalanceISKcash: "Balanse ISK kontanter",

      txtDeleteOrderHeader:
        "Er du sikker på at du vil kansellere denne bestillingen?",

      //Orders Table
      txtFundNames: "Fondsnavn",
      txtPercent: "Prosent",
      txtOrders: "Ordre",

      txtOrder: "Ordre",

      txtOrderTypeDropdown: "Ordretype",

      //OVERVIEW Table
      txtDetails: "Detaljer",
      txtName: "Navn",
      txtAccountNumber: "Kontonummer",
      txtNationalIdNumber: "ID nummer",
      txtCustomerName: "Kundenavn",

      txtTradeDateCash: "Handelsdato kontanter",
      txtNetAssetValue: "Nettoverdi",
      txtBuyWithAutogiro: "Kjøp med autotrekk",
      txtAssetManager: "Porteføljeforvalter",
      //popup
      txtFAQ: "Vanlige spørsmål",
      txtContactUs: "Kontakt oss",

      //mock
      txtNone: "Ingen",

      //login alerts
      txtTryAgain: "Prøv igjen",
      txtNoMatchForAccount: "Finner ikke konto",
      txtRateLimitWarning: "For mange innloggingsforsøk",
      txtTryAgainIn: "Prøv igjen i",

      // other
      txtLoading: "Henter",
      txtCountryDropdown: "Land",
      txtPerformance: "Utvikling",
      txtBenchmark: "Referanseindeks",
      txtSuccess: "Suksess",
      txtError: "Feil",
      txtDependencies: "Tilknytning",
      txtClose: "Avslutte",
      txtDescending: "Fallende",
      txtAscending: "Stigende",
      txtRead: "Lese",
      txtYes: "Ja",
      txtNo: "Nei",
      txtRowsPerPage: "Rader per side",

      //cards
      txtPerformanceCard: "Utvikling",
      txtNetAssetValueCard: "Nettoverdi",
      txtTradeDateCashCard: "Handelsdato kontanter",
      txtBestPerformingAssetCard: "Best utvikling",

      //info icon
      txtHover: "Tips før muspeker over diagramforklaringen!",
      txtSelectorInfoPortfolio:
        "Se hvordan investeringene dine utvikler seg! Prøv å endre gruppetype fra - og til dato og se forskjellen.Avkastning på investeringen endrer hvor mange datapunkter som vises.",
      txtSelectorInfoFund:
        "Se hvordan investeringene dine utvikler seg! Prøv å endre gruppetype fra - og til dato og se forskjellen.Avkastning på investeringen endrer hvor mange datapunkter som vises.",
      txtSelectorInfoDocument: "Her kan du se dine publiserte dokumenter.",
      txtSelectorInfoReports:
        "Her kan du generere pdf dokumenter.Velg hvilken rapport du ønsker å generere over ønsket tidsperiode.",
      txtSelectorInfoSwitch: "Her kan du kjøpe, selge eller bytte fond.",
      txtSelectorInfoUser:
        "Her kan du endre din e - post adresse og kontaktdetaljer som er knyttet opp til din konto.",

      txtCardInfoPerformance:
        "Hvordan din totalportefølje har utviklet seg i valgt periode.",
      txtCardInfoNetAssetValue: "Porteføljeverdien mellom valgte datoer.",
      txtCardInfoTradeDateCash: "Fond tilgjengelig for handel.",
      txtCardInfoDailyWinner:
        "Fond i porteføljen med best utvikling siste dag.",

      txtAccount: "Konto",

      //User info
      txtAccountName: "Kontonavn",
      txtEmail: "E - post",
      txtAdress: "Adresse",
      txtPostalCode: "Postnummer",
      txtCity: "By",
      txtCountry: "Land",
      txtHomeNumber: "Tlf.privat",
      txtMobileNumber: "Tlf.mobil",
      txtSave: "Lagre",
      txtSaveChanges: "Lagre endringer",
      txtUpdateAccount: "Oppdatere konto",
      txtUpdate: "Oppdatere",
      txtAll: "Alle",

      //FOOTER
      txtInformation: "Informasjon",
      txtCustomerService: "Kundeservice",

      //Login page
      txtNoAccount: "Inget konto ennå?",
      txtWelcomeTo: "Velkommen til",
      txtBankIDBtn: "Logg in med BankID",
      txtBecomeACustomer: "Bli kunde",
      txtCredentials: "Innloggingsdetaljer",

      txtYourInfo: "Din informasjon",

      monthsArray: [
        "januar",
        "februar",
        "mars",
        "april",
        "mai",
        "juni",
        "juli",
        "august",
        "september",
        "oktober",
        "november",
        "desember",
      ],
    },
  };

  return lng === defaultLng
    ? se.translation[input]
    : lng === "no"
    ? no.translation[input]
    : en.translation[input];
}

export default Lang;
