import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";
import Program from "./app/App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./customTheme";
import React, { useEffect } from "react";
import ErrorBoundary from "./ErrorBoundary";
// import ErrorComponent from "./ErrorComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));

function AppWrapper() {
  useEffect(() => {
    const customer = sessionStorage.getItem("css");

    // Remove existing font class
    document.body.classList.remove(
      "font-lannebo",
      "font-simplicity",
      "font-norse",
      "font-isec",
      "font-demo_green",
      "font-demo_blue",
      "font-demo_purple"
    );
    // Add font class based on customer
    document.body.classList.add(`font-${customer}`);

    // Identify the favicon link element
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    let title = "Secura Web";

    // Dynamically set the document favicon.ico && document title
    if (customer === "lannebo") {
      link.href = process.env.REACT_APP_FAVICON_URL_LANNEBO;
      title = process.env.REACT_APP_TITLE_LANNEBO || "Secura Web";
    } else if (customer === "norse") {
      link.href = process.env.REACT_APP_FAVICON_URL_NORSE;
      title = process.env.REACT_APP_TITLE_NORSE || "Secura Web";
    } else if (customer === "simplicity") {
      link.href = process.env.REACT_APP_FAVICON_URL_SIMPLICITY;
      title = process.env.REACT_APP_TITLE_SIMPLICITY || "Simplicity Web";
    } else if (
      customer === "demo_green" ||
      customer === "demo_blue" ||
      customer === "demo_purple"
    ) {
      link.href = process.env.REACT_APP_FAVICON_URL_ISEC;
      title = process.env.REACT_APP_TITLE_ISEC || "Secura Web";
    } else {
      // Isec
      link.href = process.env.REACT_APP_FAVICON_URL_ISEC;
      title = process.env.REACT_APP_TITLE_ISEC || "Secura Web";
    }

    document.getElementsByTagName("head")[0].appendChild(link);
    document.title = title;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Program />
    </ThemeProvider>
  );
}

root.render(
  <ErrorBoundary>
    <AppWrapper />
    {/* <ErrorComponent /> */}
  </ErrorBoundary>
);

reportWebVitals();
